'use client';

import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import FocusTrap from 'focus-trap-react';

interface ToastProps {
  message: string;
  isVisible: boolean;
  onClose: () => void;
  duration?: number;
}

const Toast: React.FC<ToastProps> = ({ message, isVisible, onClose, duration = 5000 }) => {
  const [progress, setProgress] = useState(100);
  const [mounted, setMounted] = useState(false);
  const minDuration = 3000;
  const toastRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const animationDuration = Math.max(duration, minDuration);
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prevProgress - (100 / (animationDuration / 100));
        });
      }, 100);

      const closeTimer = setTimeout(() => {
        onClose();
      }, animationDuration);

      return () => {
        clearInterval(timer);
        clearTimeout(closeTimer);
      };
    }
  }, [isVisible, duration, onClose]);

  const toastContent = (
    <AnimatePresence>
      {isVisible && (
        <FocusTrap
          focusTrapOptions={{
            initialFocus: false,
            clickOutsideDeactivates: true,
            fallbackFocus: () => toastRef.current || document.body,
          }}
        >
    <motion.div
  ref={toastRef}
  initial={{ opacity: 0, y: 50 }}
  animate={{ opacity: 1, y: 0 }}
  exit={{ opacity: 0, y: 50 }}
  className="fixed inset-x-0 bottom-8 mx-auto bg-light text-blue p-6 rounded-lg shadow-lg z-[9999] text-md border-2 border-blue w-11/12 sm:max-w-sm"
  role="alert"
  aria-live="assertive"
  tabIndex={-1}
>
  <button
    onClick={onClose}
    className="absolute top-3 right-3 bg-blue border-2 border-light text-light rounded-full p-1.5 !hover:bg-light !hover:text-blue hover:border-blue transition-colors ease-in-out duration-300 focus-button"
    aria-label="Close notification"
  >
    <X size={20} />
  </button>
  <div className="pr-6">{message}</div>
  <div className="mt-2 h-1 bg-blue rounded-full overflow-hidden">
    <motion.div
      className="h-full bg-light"
      initial={{ width: '100%' }}
      animate={{ width: `${progress}%` }}
      transition={{ duration: 0.1, ease: 'linear' }}
    />
  </div>
</motion.div>

        </FocusTrap>
      )}
    </AnimatePresence>
  );

  if (!mounted) return null;
  
  return createPortal(toastContent, document.body);
};

export default Toast;